// Bootstrap Overides

//Typeography
.h1 {
  font-size: 40px;
  font-weight: 400;
}

.h2 {
  font-size: 24px;
  font-weight: 400;
}

.h3 {
  font-size: 18px;
  font-weight: 400;
}

.h4 {
  font-size: 18px;
  font-weight: 500;
}

.h5 {
  font-size: 16px;
  font-weight: 400;
}

h1 {
  font-size: 40px;
  font-weight: 400;
}

h2 {
  font-size: 24px;
  font-weight: 400;
}

h3 {
  font-size: 18px;
  font-weight: 400;
}

h4 {
  font-size: 18px;
  font-weight: 500;
}

h5 {
  font-size: 16px;
  font-weight: 400;
}

lead {
  font-size: 16px;
  font-weight: 500;
}

.base {
  font-size: 14px;
}

.small {
  font-size: 12px;
  font-weight: 500;
}

.xsmall {
  font-size: 8px;
  font-weight: 400;
}

//Buttons
.btn-primary,
.btn-success {
  &:hover {
    background-color: $primary-hover;
    border-color: $primary-hover;
  }

  &:active {
    background-color: $primary-active !important;
    color: $dark !important;
    border-color: $primary-active !important;
  }
}

.btn-secondary,
.btn-danger {
  &:hover {
    background-color: $secondary-900;
    border-color: $secondary-900;
  }

  &:active {
    background-color: $secondary-300 !important;
    color: $dark !important;
    border-color: $secondary-300 !important;
  }
}

.btn-info,
.btn-warning {
  &:hover {
    background-color: $info-hover;
    border-color: $info-hover;
  }

  &:active {
    background-color: $info-active !important;
    color: $dark !important;
    border-color: $info-active !important;
  }
}

.btn-white {
  border: 1px solid black;

  &:hover {
    background-color: $white;
    border-color: $gray-800;
    border: 1px solid black;
  }

  &:active {
    background-color: $white !important;
    color: $dark !important;
  }
}

.btn-eye {
  border: none;
  background-color: transparent !important;

  &:hover,
  &:active,
  &:focus-visible {
    background-color: transparent !important;
    color: $dark;
  }
}

.btn-transparent {
  border: 1px $gray-800 solid;
  background-color: transparent !important;

  &:hover,
  &.active {
    background-color: transparent !important;
    border: 1px $primary solid;
    box-shadow: 5px 5px 5px $gray-800;
  }
}

.btn-card {

  &:hover,
  &.active {
    border: 1px $primary-active solid;
    box-shadow: 5px 5px 5px $gray-800;
  }
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 20px;
  line-height: 1;
  background-color: #92DEE0;
  border: 0px !important;
  color: black !important;
  margin-left: 1rem;
}

.nav_item {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.status-pill {
  height: 28px;
}

//Form
.form-label {
  color: $dark-900 !important;
}

.form-control {
  color: $dark !important;
  border: none !important;
  border-bottom: 1px solid $gray !important;
  box-shadow: none !important;
  border-radius: 5px 5px 0px 0px !important;
  transition: all 0.2s;

  &:focus-visible,
  &:hover {
    background-color: lighten($primary-active, 24%) !important;
    border: none !important;
    border-bottom: 2px solid $primary !important;
    border-radius: 5px 5px 0px 0px !important;
    transition: all 0.2s;
  }
}

.form-control-border {
  color: $dark !important;
  border: 1px solid $primary-hover !important;
  border-radius: 5px;
  box-shadow: none !important;
  transition: all 0.2s;

  &:focus-visible,
  &:hover {
    background-color: lighten($primary-active, 24%) !important;
    border: 2px solid $primary !important;
    transition: all 0.2s;
  }
}

.form-select {
  background-image: url('../images/formSelect.svg');
}

.validation-form {
  border: 2px $gray-800 solid !important;
  border-radius: 5px;

  &:focus-visible,
  &:hover,
  &.active {
    background-color: white !important;
    border-radius: 5px !important;
    border: 2px $primary solid !important;
    transition: all 0.2s;
  }
}

.form-check-label {
  padding: 2px !important;
  align-items: center;
}

//Modal
.modal-white .modal-content {
  background-color: #FFFFFF !important;
  border: white;
}


.modal-content {
  background-color: $secondary-300;
}

.modal-header {
  border: none;
  text-align: center;
}

.modal-footer {
  border: none;
  display: flex;
  justify-content: center;
}

.modal-title {
  text-align: center;
}

.modal-body {
  text-align: center;
}

//Custom searchbox
.custom-searchbox {
  .input-group-text {
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    border: none !important;
    background-color: $gray-250 !important;
  }

  .form-control {
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    border: none !important;
    background-color: $gray-250 !important;
  }
}

// Custom tabs
.nav-tabs.custom-tabs {
  .nav-link {
    border-bottom: none !important;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
  }

  .nav-link.active {
    background-color: $gray-250 !important;
  }
}

// Checkboxes/Radios
// .form-check-input:checked[type=checkbox] {
//   // background-image: none !important;
// }

.form-check-input:checked[type=radio] {
  background-image: none !important;
}

// Alerts
.alert {
  z-index: 3 !important;
  color: $dark !important;
}

.card {
  border: none;
}

.btn-lg {
  @extend .rounded-pill;
  @extend .py-8;
}

.nav-tabs,
.nav-link {
  font-size: 16px;
  color: $dark-500;
  font-weight: 600;
  border: none !important;

  &.active {
    color: $primary !important;
    border: none;

    font-weight: 800;

    path {
      fill: $primary !important;
    }
  }
}

a:hover {
  font-weight: 600 !important;
}

.form-check-input {
  margin-top: 0.15rem;
  border-radius: 50% !important;
  &:checked {
    background-color: $primary-hover !important;
    border: $primary-hover !important;
  }
}

.form-check-radio-custom .form-check-input {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='white'/></svg>");
  background-position: left center;
  background-color: $dark-500 !important;
  margin-top: auto;
  border-color: $dark-500 !important;;
  border-radius: 2em !important;
  width: 40px !important;
  height: 20px !important;
  &:checked {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='white'/></svg>") !important;
    background-position: right center;
    background-color: $primary-active !important;
    border: $dark-500 !important;
  }
}

.custom-shadow {
  box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05) !important;
}

.custom-dropdown-toggle::after {
  display: none !important;
}

.custom-nav-dropdown {
  margin-top: 3vh !important;
  left: -10vw !important;
}

.dropdown-alternate-shading {
  &:nth-child(2n) {
    background-color: $gray-250 !important;
  }

  &:hover {
    background-color: $gray-800 !important;
    font-weight: 500 !important;
  }
}

// DATEPICKER
.react-datepicker__header {
  background-color: $primary !important;

  div {
    color: white !important;
  }
}

.react-datepicker__day--keyboard-selected {
  background-color: $primary-hover !important;
}

//

.off-canvas {
  width: 596px !important;
}

.bulk-action-btn {
  &:hover {
    color: $primary !important;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    pointer-events: none;
  }

  100% {
    opacity: 1;
    pointer-events: all;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    pointer-events: none;
  }

  100% {
    opacity: 0;
    pointer-events: none;
  }
}

.cursor .cursor-pointer {
  cursor: pointer !important;
}

.row-hover-effect {
  border: 1px solid $gray !important;

  &:hover {
    border: 1px solid $primary-active !important;
    box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05) !important;
    cursor: pointer;
  }
}

.dashed-border {
  border-radius: 15px;
  border: 2px dashed $primary-hover;
}


@media only screen and (min-width: 992px) {
  .left-panel-border {
    border-right: 2px #AFC9CC solid;
  }
}