$primary: #41696c;
$primary-hover: #609599;
$primary-active: #92DEE0;
$primary-bg: #d2fdff;
$primary-100: #eff4f5;

$secondary: #bc7057;
$secondary-900: #e7c0aa;
$secondary-500: #fbe5db;
$secondary-300: #fcede6;
$secondary-200: #fef7f4;

$info: #ffd292;
$info-active: #ffe8c8;
$info-hover: #fff4e4;

$danger: #d23b32;
$danger-active: #ffe6e4;
$danger-hover: #f69c97;

$warning: #b88c1a;
$warning-active: #ffd27a;
$warning-hover: #fff2cf;

$success: #3f8452;
$success-active: #ddf1e5;
$success-hover: #74dd9e;

$dark: #161616;
$dark-900: #505050;
$dark-500: #8a8a8a;

$gray: #ededed;
$gray-800: #f2f2f2;
$gray-200: #f6f6f6;
$gray-250: #fafafa;

$transparent: transparent;
$white: white;
$black: black;

$theme-colors: (
  "primary": $primary,
  "primary-hover": $primary-hover,
  "primary-active": $primary-active,
  "primary-bg": $primary-bg,
  "primary-100": $primary-100,
  "secondary": $secondary,
  "secondary-900": $secondary-900,
  "secondary-500": $secondary-500,
  "secondary-300": $secondary-300,
  "secondary-200": $secondary-200,
  "info": $info,
  "info-active": $info-active,
  "info-hover": $info-hover,
  "danger": $danger,
  "danger-active": $danger-active,
  "danger-hover": $danger-hover,
  "warning": $warning,
  "warning-active": $warning-active,
  "warning-hover": $warning-hover,
  "success": $success,
  "success-active": $success-active,
  "success-hover": $success-hover,
  "dark": $dark,
  "dark-900": $dark-900,
  "dark-500": $dark-500,
  "gray": $gray,
  "gray-800": $gray-800,
  "gray-200": $gray-200,
  "gray-250": $gray-250,
  "transparent": $transparent,
  "black": $black,
  "white": $white,
);

$body-color: $dark;
$dropdown-link-color: $dark;
$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: $primary;
$navbar-dark-hover-color: rgba($white, 0.9);
$grid-gutter-width: 32px;
$input-border-color: $dark;
$small-font-size: 12px;
$font-size-base: 0.8125rem;
$lead-font-size: 16px;
$h5-font-size: 16px;
$h4-font-size: 18px;
$h3-font-size: 24px;
$h2-font-size: 24px;
$h1-font-size: $font-size-base * 2.5;
$headings-line-height: 1.25;
$btn-border-radius: 24px;
$btn-border-radius-lg: 24px;
$btn-border-radius-sm: 24px;
$card-border-radius: 16px;
$input-border-radius: 0px;
$font-family-sans-serif: avenir;
$font-family-monospace: avenir;
$enable-responsive-font-sizes: true;
$enable-negative-margins: true;
$nav-pills-link-active-color: $white;
$nav-pills-link-active-bg: $primary-active;

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    8: (
      $spacer * 0.5,
    ),
    16: (
      $spacer,
    ),
    24: (
      $spacer * 1.5,
    ),
    32: (
      $spacer * 2,
    ),
    40: (
      $spacer * 2.5,
    ),
    48: (
      $spacer * 3,
    ),
    56: (
      $spacer * 3.5,
    ),
    64: (
      $spacer * 4,
    ),
    72: (
      $spacer * 4.5,
    ),
    80: (
      $spacer * 5,
    ),
    88: (
      $spacer * 5.5,
    ),
    96: (
      $spacer * 6,
    ),
    104: (
      $spacer * 6.5,
    ),
    112: (
      $spacer * 7,
    ),
    120: (
      $spacer * 7.5,
    ),
    128: (
      $spacer * 8,
    ),
    136: (
      $spacer * 8.5,
    ),
    144: (
      $spacer * 9,
    ),
    152: (
      $spacer * 9.5,
    ),
    168: (
      $spacer * 10.5,
    ),
  ),
  $spacers
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px,
  xxl: 1440px,
) !default;

$box-shadow-top-lg: 0 -1rem 3rem -1rem rgba($black, 0.175);
