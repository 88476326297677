@each $name, $color in $theme-colors {
  .control-#{$name} {
    @extend .text-dark;
    @extend .border-#{$name} !optional;
    @extend .shadow-none;
    background-color: scale-color($color, $lightness: 50%) !important;
    transition: all 0.2 ease-in-out;
    box-shadow: none !important;
    border-radius: 5px 5px 0px 0px !important;
    &:focus-visible,
    &:hover {
      background-color: lighten($color, 10%) !important;
      border-bottom: 1px solid scale-color($color, $saturation: 70%);
      transition: all 0.2 ease-in-out;
    }
  }
}

$tabelColums: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);
@each $amount in $tabelColums {
  .table-td-#{$amount} {
    width: calc(100% / $amount);
  }
}

.z-index-n1 {
  z-index: -1;
}

.bill-summary-container {
  width: 100%;
}

@media only screen and (min-width: map-get($grid-breakpoints, lg)) {
  .bill-summary-container {
    width: 70%;
  }
}

.sublead {
  font-size: 14px;
}

.pill-btn-height {
  height: 42px;
}

.btn-outline-primary.text-dark {
  &:hover {
    color: white !important;

    small {
      color: white !important;
    }
  }
}

.bill-card {
  border: 2px solid $gray-800;

  &:hover {
    border-color: $primary-active;
  }
}

.box-shadow-sm-hover {
  &:hover {
    box-shadow: $box-shadow-sm;
  }
}

.border-primary-active-focus {
  &:focus {
    border: 1px solid $primary-active;
  }
}

.modal {
  --bs-modal-border-radius: 25px;
}
.coming-soon-modal-header-image {
  background-image: url('../images/comingSoonHeader.svg');
  background-size: cover;
  color: $primary;
  height: 12vh;
  border-radius: 25px 25px 0px 0px;
  .btn-close {
    z-index: 999;
  }
}
.coming-soon-modal-header {
  color: $primary;
  height: 12vh;
  z-index: 999;
  border-radius: 25px 25px 0px 0px;
}
.coming-soon-modal-title {
  position: absolute;
  width: 100%;
  height: 12vh;
  margin-top: 1rem
}
.coming-soon-modal-body {
  border-radius: 0px 0px 25px 25px ;
  background-color: white;
}
.custom-modal-body {
  border-radius: 0px 0px 25px 25px ;
}
.custom-bullet {
  ::marker {
    color: $primary;
  }
}

.overflow-scroll-y {
  overflow-y: auto;
}

// .right-column {
//   position: sticky;
//   top: 0; /* Stick to the top of the container */
//   flex: 0; /* Don't grow; maintain its size */
//   height: 100%; /* Ensure it takes the full height of the container */
//   overflow-y: auto; /* Add vertical scroll to the right column if it overflows */
// }